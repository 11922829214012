<template>
    <div>
        <headTitle :isMain="3"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-4智能仓储.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">智能仓储</div>
            <div class="mobile-head-explain-content">布局原料赋码，助力品质生产</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:6.2rem">质量安全</div>
            <div class="ware-content1" style="margin-top:1rem">对原料（尤其主剂、农产品）的存储环境、有效期管理要求严格;<br/>
                生产投料配比与投料量的执行易发生少投、错投等问题，影响产品质量安全;</div>
            <div class="ware-title" style="margin-top:4.8rem">经验依赖</div>
            <div class="ware-content1" style="margin-top:1rem">原料需求呈现小批次、多种类，增加人工管理难度;<br/>
                原料在入库、发料、投料各场景管理维度不同，依赖人工经验判断;</div>
            <div class="ware-title" style="margin-top:4.8rem">问题追溯</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">原料流转信息纸质记录，各环节信息不透明，追溯流程不标准导致溯源困难;<br/>
                原料没有有效身份标识，无法精准定位物料批次以及具体托盘物料。</div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" style="margin-bottom:5rem" src="../../assets/image/example/02-5解决方案.png"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-5成本.svg"/>
                    <div class="title1">成本</div>
                    <div class="title2" style="color: #CE1B1B;">15%<img class="up-down" src="../../assets/image/example/下降.svg"/></div>
                    <div class="title3">缩短制造周期</div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-5效率.svg"/>
                    <div class="title1">效率</div>
                    <div class="title2">70%<img class="up-down" src="../../assets/image/example/上升.svg"/></div>
                    <div class="title3">数据分析效率提高</div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-5溯源.svg"/>
                    <div class="title1">溯源</div>
                    <div class="title2">87%<img class="up-down" src="../../assets/image/example/上升.svg"/></div>
                    <div class="title3">溯源效率提高</div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-5数据.svg"/>
                    <div class="title1">数据</div>
                    <div class="title2">100%<img class="up-down" src="../../assets/image/example/上升.svg"/></div>
                    <div class="title3">数据准确性实时性</div>
                </div>
            </div>
            <div class="ware-title" style="margin-top:6.2rem">过程校验，保证产品质量</div>
            <div class="ware-content1" style="margin-top:1rem">建立原料临期预警、FEFO管理，并且在原料流转过程中采用PDA作业，系统自动校验，实现原料至成品过程控制，保证产品质量安全；</div>
            <div class="ware-title" style="margin-top:6.2rem">业务自动流转，降低人工依赖</div>
            <div class="ware-content1" style="margin-top:1rem">原料采用数字化信息采集，建立业务流程自动流转机制，结合系统参数控制，引导式操作，摆脱经验依赖，提高作业质量；</div>
            <div class="ware-title" style="margin-top:6.2rem">原料赋码，高效溯源</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">通过标签赋码、统一托盘进行物料管理，结合物料批次实现入库、发料、投料全链路可溯，精准定位问题源头，高效溯源。</div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    height: 40.7rem;
    margin: auto;
}
.ware-img2{
    width: 70.1rem;
    height: 63.3rem;
}
.purchase-box{
    margin: auto;
    width: 55rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        margin-top: 4rem;
        width: 25.6rem;
        height: 26.5rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 1.2rem 0px rgba(233,233,233,0.6);
        justify-content: center;
        .logo{
            // margin: 3rem 0 0 9.6rem;
            width: 6.4rem;
            height: 6.4rem;
            display: flex;
            margin: auto;
            margin-top: 3rem;
        }
        .up-down{
            margin-top: 0.7rem;
            margin-left: 0.4rem;
            width: 1.6rem;
            height: 1.6rem;
        }
        .title1{
            // width: 6rem;
            // height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;
            text-align: center;
            margin-top: 2.2rem;
        }
        .title2{
            width: 6.1rem;
            height: 3rem;
            font-size: 3rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            display: flex;
            line-height: 3rem;

            margin: auto;
            margin-top: 2rem;
        }
        .title3{
            // width: 9.6rem;
            text-align: center;
            height: 1.6rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;
            margin: auto;
            margin-top: 1rem;
        }
    }
}
</style>